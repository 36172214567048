<template>
  <div class="notifications--container">
    <div v-if="loadState === undefined" class="table-loading" />
    <div class="usage-page--interior-status-container">
      <flow-error v-if="loadState === 'error'" name="Notification configuration" state="error" />
      <flow-error v-if="loadState === 'maintenance'" name="Notification configuration" state="maintenance" />
      <flow-error v-if="loadState === 'unavailable'" name="Notification configuration" state="unavailable" img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
    </div>
    
    <template v-if="loadState === 'complete'">
    <div v-if="noContactsOnAccount">
      <banner-one-action
        bannerColor="blue"
        :message="'There are no contacts for this account. Before turning on notifications, add a contact.'"
        :linkText="'Contacts Page'"
        :url="'/account/notifications/contacts/'"
      ></banner-one-action>
    </div>
        <div class="notifications__overview--sections" v-for="(alert, index) in alerts" :key="alert.alertTypeId" >
          <!-- Icon -->
          <img class="notifications__overview--icon" :src="alert.iconUrl">
          <!-- Type -->
          <div class="notifications__overview--type">
            <p class="notifications__overview--type--heading">{{ alert.name }}</p>
            <p class="notifications__overview--type--description">{{ alert.description }}</p>
            <a v-if="alert.code === 'USAGE_NOTIFICATIONS' && !noContactsOnAccount" class="notifications__overview--configure-usage-alerts" href="/account/configure/usage-alert/">
              Customize Usage Alert Threshold
            </a>
          </div>
          <!-- Status -->
          <div class="notifications__overview--status" v-if="!noContactsOnAccount">
            <div v-if="alert.hasSubscriptions && !alert.buttonLoading" class="notifications__overview--status--span">
              <img class="notifications__overview--status--icon" src="/wp-content/themes/gmptwentynineteen/assets/images/check-circle-small-cropped@3x.png" alt="">
              <span v-if="alert.subscriptionCount === 0 || alert.subscriptionCount > 1">Enabled for {{ alert.subscriptionCount }} Contacts</span>
              <span v-else>Enabled for {{ alert.subscriptionCount }} Contact</span>
            </div>
            <router-link v-if="alert.hasSubscriptions && buttonPressed !== index" class="notifications__overview--status--button gds-button gds-compact gds-secondary gds-round" to="/notifications/contacts/">View Contacts</router-link>
            <button v-if="!alert.hasSubscriptions && buttonPressed !== index" class="notifications__overview--status--button gds-button gds-compact gds-round" @click="subscribe(alert, index)">Turn On</button>
            <button v-if="buttonPressed === index" class="notifications__overview--status--button button-loading gds-button gds-compact gds-secondary gds-round"></button>
          </div>
          <modal-add-contact v-if="showAddContactModal" :currentAlert="subscriptionFailed" @complete="showAddContactModal = undefined" />
          <div v-if="showChangeErrorBanner && buttonError === index" class="form-message-box gds-space-stack-m">There was an error {{ changeErrorAction }}.</div>
        </div>
    </template>
  </div>
</template>

<script>

import BannerOneAction from '../../BannerOneAction.vue';
import ModalAddContact from './ModalAddContact.vue';
import GMPAPI from '../../../services/gmpapi';
import { DumpError } from '../../../utilities';

export default {
  name: "SubpageNotificationOverview",
  components: {
    BannerOneAction,
    ModalAddContact,
  },
  data() {
    return {
      loadState: undefined,
      alerts: [],
      contacts: [],
      noContactsOnAccount: false,

      buttonPressed: undefined,
      buttonError: undefined,
      subscriptionFailed: undefined,
      showAddContactModal: undefined,
      showChangeErrorBanner: undefined,
      changeErrorAction: ''
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    }
  },
  async mounted() {
    await this.refreshData(this.currentAccount.accountNumber);
  },
  watch: {
    async currentAccount() {
      await this.refreshData(this.currentAccount.accountNumber);
    }
  },
  methods: {
    async refreshData(accountNumber) {
      this.loadState = undefined;
      this.buttonError = undefined;
      try {
        this.alerts = await GMPAPI.GetAlertTypes(accountNumber);
        if (!this.alerts || !this.alerts.length) {
          this.loadState = 'unavailable';
          return;
        }
        this.contacts =  await GMPAPI.GetAlertContacts(accountNumber);
        if (!this.contacts || !this.contacts.length) {
          this.loadState = 'complete';
          this.noContactsOnAccount = true;
          return;
        }
        this.noContactsOnAccount = false;
      } catch (err) {
        this.loadState = 'error';
        DumpError('Error on read', err);
        return;
      }
      this.setSubscriptionInfo();
      this.loadState = 'complete';
    },

    setSubscriptionInfo() {
      for (let index=0; index < this.alerts.length; index++) {
        const subscriptionCount = this.countSubscriptions(this.alerts[index].alertTypeId);
        this.alerts[index].hasSubscriptions = subscriptionCount > 0;
        this.alerts[index].subscriptionCount = subscriptionCount;
      }
    },

    countSubscriptions(alertId) {
      let count = 0;
      for (let i = 0; i < this.contacts.length; i++) {
        const contact = this.contacts[i];
        if (Array.isArray(contact.subscriptions)) {
          for (let j = 0; j < contact.subscriptions.length; j++) {
            const subscription = contact.subscriptions[j];
            if (subscription && subscription.alertType && subscription.alertType.alertTypeId === alertId) {
              count++;
            }
          }
        }
      }
      return count;
    },

    async subscribe(alert, index) {
      this.buttonPressed = index;
      try {
        for (let i = 0; i < this.contacts.length; i++) {
          const contact = this.contacts[i];
          for (let j = 0; j < alert.channels.length; j++) {
            if (alert.channels[j].channelId === contact.channel.channelId) {
              await GMPAPI.SubscribeAlert(this.currentAccount.accountNumber, contact.contactId, alert.alertTypeId);
            }
          }
        }
        this.showChangeErrorBanner = undefined;
        await this.refreshContacts(this.currentAccount.accountNumber);
        const currentAlertSubscriptionCount = this.alerts[index].subscriptionCount;
        if (!currentAlertSubscriptionCount || currentAlertSubscriptionCount === 0) {
          this.showAddContactModal = true;
          this.subscriptionFailed = this.alerts[index];
        }
      } catch (err) {
        this.showChangeErrorBanner = true;
        this.changeErrorAction = 'subscribing';
        this.buttonError = index;
        DumpError('Error on read', err);
      }
      this.buttonPressed = undefined;
    },

    async unsubscribe(alert, index) {
      this.buttonPressed = index;
      try {
        for (let i = 0; i < this.contacts.length; i++) {
          const contact = this.contacts[i];
          if (Array.isArray(contact.subscriptions)) {
            for (let j = 0; j < contact.subscriptions.length; j++) {
              const subscription = contact.subscriptions[j];
              const subscriptionId = subscription.subscriptionId;
              if (subscription && subscriptionId && subscription.alertType && subscription.alertType.alertTypeId === alert.alertTypeId) {
                await GMPAPI.UnSubscribeAlert(this.currentAccount.accountNumber, subscriptionId);
              }
            }
          }
        }
        this.showChangeErrorBanner = undefined;
        await this.refreshContacts(this.currentAccount.accountNumber);
      } catch (err) {
        this.showChangeErrorBanner = true;
        this.changeErrorAction = 'unsubscribing';
        this.buttonError = index;
        DumpError('Error on read', err);
      }
      this.buttonPressed = undefined;
    },

    async refreshContacts(accountNumber) {
      try {
        this.contacts =  await GMPAPI.GetAlertContacts(accountNumber);
      } catch (err) {
        this.loadState = 'error';
        DumpError('Error on read', err);
        return;
      }
      this.setSubscriptionInfo();
    },
  }
};
</script>