<template>
  <div class="subpage-dashboards subpage-billing-payment--dashboard">
    <h1 class="my-account__title gds-space-stack-l subpage-dashboards--left-spacer">Notifications</h1>
    <div class="my-account__tab-nav gds-flex-container gds-flex-container--left subpage-dashboards--left-spacer">
      <router-link to="/notifications" class="my-account__tab-nav-item" >Overview</router-link>
      <router-link to="/notifications/contacts" class="my-account__tab-nav-item" >Contacts</router-link>
      <router-link v-if="isDeviceFeatureEnabled" to="/notifications/devices" class="my-account__tab-nav-item">Mobile Devices</router-link>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>

import { DumpError } from '../../../utilities';
import { isFeatureEnabled } from "../../../services/featureflags"

export default {
  name: "SubpageNotifications",
  components: {
  },
  data() {
    return {
      isDeviceFeatureEnabled: false
    };
  },
  computed: {
    
  },
  async mounted() {
    try {
      this.isDeviceFeatureEnabled = await this.isDevicesFeatureEnabled();
    } catch (err) {  
      DumpError("Error mounting SubpageNotifications component ", err);
    }
  },
  methods: {
    async isDevicesFeatureEnabled() {
      return await isFeatureEnabled('FF_DeviceContacts', false);
    }, 
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>