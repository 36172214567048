<template>
  <div class="gmp-modal">
    <div class="notifications__overview--modal--box">
      <FocusLock>
        <header class="notifications__overview--modal--header">
          <button @click="close()" class="close gds-button-circle gds-icon-close-small gds-secondary">
            <span class="gds-visibility--hide-visually">Close</span>
          </button>
          <div class="notifications__overview--modal--headline">There are no eligible contacts on the account.</div>
        </header>
        <div class="notifications__overview--modal--body">
          <div class="gds-space-stack-l">
            Please add a new {{ eligibleChannelsList }} contact.
          </div>
          <button @click="close()" type="button" class="gds-button gds-secondary gds-compact gds-space-stack-m">
            <span class="gds-button__text">Cancel</span>
          </button>
          <button @click="routeToContacts()" class="gds-button gds-compact gds-secondary gds-full-red-button gds-space-stack-m">
            <span class="gds-button__text">Add Contacts</span>
          </button>
        </div>
      </FocusLock>
    </div>
  </div>
</template>

<script>

export default {
  name: "ModalAddContact",
  props: ["currentAlert"],
  data() {
    return {
      eligibleChannelsList: ''
    };
  },
  mounted() {
    this.eligibleChannelsList = this.findEligibleChannels(this.currentAlert.channels);
  },
  methods: {
    findEligibleChannels(channels) {
      let alertChannelsList = undefined;
      for (let i = 0; i < channels.length; i++) {
        alertChannelsList += channels[i].name.toLowerCase();
        if (channels.length > 1) {
          if (i !== channels.length - 1) {
            alertChannelsList += ", "
          }
          if (i === channels.length - 2) {
            alertChannelsList += "or "
          }
        }
      }
      return alertChannelsList;
    },

    routeToContacts() {
      this.$router.push('/contacts');
    },
    
    close() {
      this.$emit("complete");
    }
  },
};
</script>