var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gmp-modal" }, [
    _c(
      "div",
      { staticClass: "notifications__overview--modal--box" },
      [
        _c("FocusLock", [
          _c(
            "header",
            { staticClass: "notifications__overview--modal--header" },
            [
              _c(
                "button",
                {
                  staticClass:
                    "close gds-button-circle gds-icon-close-small gds-secondary",
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [
                  _c("span", { staticClass: "gds-visibility--hide-visually" }, [
                    _vm._v("Close")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "notifications__overview--modal--headline" },
                [_vm._v("There are no eligible contacts on the account.")]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "notifications__overview--modal--body" }, [
            _c("div", { staticClass: "gds-space-stack-l" }, [
              _vm._v(
                "\n          Please add a new " +
                  _vm._s(_vm.eligibleChannelsList) +
                  " contact.\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "gds-button gds-secondary gds-compact gds-space-stack-m",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              },
              [
                _c("span", { staticClass: "gds-button__text" }, [
                  _vm._v("Cancel")
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "gds-button gds-compact gds-secondary gds-full-red-button gds-space-stack-m",
                on: {
                  click: function($event) {
                    return _vm.routeToContacts()
                  }
                }
              },
              [
                _c("span", { staticClass: "gds-button__text" }, [
                  _vm._v("Add Contacts")
                ])
              ]
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }